<template>
  <vf-carousel-elevate
    :key="renderKey"
    ref="gallery"
    class="group bg-white"
    class-controls="p-1 m-2 rounded-sm duration bg-transparent <lg:!op-0 group-hover:op-100 <md:hidden @hactive:bg-white/90"
    data-test-id="product-card-gallery"
    show-arrows-on-hover-only
    size-controls="md"
  >
    <div
      v-for="(item, key) in _items"
      :key
      class="relative w-full"
      :style="{ 'aspect-ratio': mediaSize.width / mediaSize.height }"
    >
      <component
        :is="item.type === 'image' ? BasePicture : BaseVideo"
        :ref="(el) => media[key] = isVideo(el) ? el : null"
        v-bind="item"
        class="absolute-0 full cursor-pointer contain"
        :class="{ 'bg-black': item.type === 'video' }"
        @click="$emit('click-gallery-item')"
      />
      <base-button
        v-if="item.type === 'video'"
        :aria-label="media[key]?.paused ? $t.videoPlay : $t.videoPause"
        class="absolute bottom-0 right-0 mb-2 mr-2 p-2 c-grey-60"
        style="mix-blend-mode: difference"
        @click="media[key]?.toggle"
      >
        <vf-icon :name="media[key]?.paused ? 'play' : 'pause'" size="lg" />
      </base-button>
    </div>

    <template #pagination="{ activeItem }">
      <div
        v-if="_items.length"
        class="absolute inset-x-0 bottom-0 z-1 flex duration lg:op-0 group-hover:lg:op-100"
      >
        <div
          v-for="(_, key) in _items"
          :key
          class="grow duration"
          :class="activeItem === key ? 'bg-grey-10' : 'bg-grey-50'"
          style="height: 0.125rem"
        />
      </div>
    </template>
  </vf-carousel-elevate>
</template>

<script lang="ts" setup>
import type { ProductGalleryExtended } from '#root/api/clients/product/data-contracts'
import type { VfCarouselElevate } from '#components'
import { BasePicture, BaseVideo } from '#components'

const props = defineProps<{
  items: ProductGalleryExtended
  name: string
}>()

defineEmits<{
  'click-gallery-item': []
}>()

const { mediaSize, mediaTransformations } = useAppConfig().components.product.card
const gallery = ref<typeof VfCarouselElevate>()
const media = ref<(null | typeof BaseVideo)[]>([])
const renderKey = ref(0)

const _items = computed(() => props.items.map((item) => ({
  ...item,
  width: mediaSize.width,
  height: mediaSize.height,
  ...(item.type === 'image'
    ? {
        src: getImageTransformations(item.src, { ...mediaSize, ...mediaTransformations }),
        alt: item.alt || props.name,
      }
    : {}
  ),
  ...(item.type === 'video'
    ? {
        src: item.src,
        meta: item.meta,
        poster: item.poster,
        loop: true,
        controls: false
      }
    : {}
  )
})))

function isVideo(el: unknown): el is typeof BaseVideo {
  return typeof el === 'object' && el !== null && 'paused' in el
}

onActivated(() => {
  // recreates the carousel on keep-alive activate for PLP and prevents memory leaks
  renderKey.value += 1
})

watch(() => gallery.value?.currentIndex, (current, previous) => {
  const previousVideo = isVideo(media.value[previous]) ? media.value[previous] : null
  if (previousVideo && !previousVideo.paused.value)
    previousVideo.pause()
  media.value[current]?.play?.()
})
</script>
